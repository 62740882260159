import { defineRouting } from 'next-intl/routing';
import { createNavigation } from 'next-intl/navigation';

export const locales = ['en'];

export const routing = defineRouting({
  // A list of all locales that are supported
  locales,

  // Used when no locale matches
  defaultLocale: 'en',

  // Remove or change this once we support multiple languages
  // See https://next-intl.dev/docs/routing#locale-prefix
  localePrefix: 'as-needed',
});

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const {
  Link,
  redirect: _redirect,
  usePathname,
  useRouter,
  getPathname,
} = createNavigation(routing);

// Enable type narrowing after calling `redirect`
export const redirect: typeof _redirect = _redirect;
