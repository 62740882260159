import { ReactNode } from 'react';

// These tags are available by default
type Tag = 'br' | 'strong' | 'i';

type TagFunction = (chunks: ReactNode) => ReactNode;

type Tags = Record<Tag, TagFunction>;

type Props = {
  children(tags: Tags): ReactNode;
};

const defaultTags: Tags = {
  br: () => <br />,
  strong: (chunks: ReactNode) => <strong>{chunks}</strong>,
  i: (chunks: ReactNode) => <i>{chunks}</i>,
};

// From https://next-intl.dev/docs/usage/messages#rich-text
export default function RichText({ children }: Props) {
  return <>{children(defaultTags)}</>;
}
