'use client';

import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  getAuth,
  onAuthStateChanged,
  isSignInWithEmailLink as isSignInWithEmailLinkFirebase,
  User,
} from 'firebase/auth';
import { Link } from 'src/i18n/routing';
import styled from 'styled-components';
import { useTranslations } from 'next-intl';

import { GlobalSearchContext, searchForTerm } from '../../search';
import appStoreBadge from '../../images/badge-app-store.png';
import playStoreBadge from '../../images/badge-google-pay.png';
import { Item } from '../../types';
import { ContentContext } from '../../contexts';
import ItemInList from '../ItemInList';
import { Listing } from '../common';
import { debounce } from '../../utils';
import RichText from '../RichText';

const StyledLink = styled(Link)`
  color: #777;
  font-size: small;
`;

const CloseButton = styled.button`
  font-size: 0.7em;
  vertical-align: text-bottom;
  margin-left: 1em;
  margin-right: 1em;
`;

const SearchArea = styled.div`
  margin-top: 1em;
  background-color: rgb(240, 238, 235);
  border-radius: 0.5em;
  padding: 0.5em;

  @media (max-width: 350px) {
    border-radius: 0;
    margin-left: -26px;
    margin-right: -26px;
  }
`;

interface WrapperProps {
  $wide: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  max-width: ${(props) => (props.$wide ? '64em' : '36em')};
  min-width: 268px;
  min-height: calc(100vh - 2 * 16px);
  margin: 0 auto;
  padding: 16px;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

const Footer = styled.footer`
  margin-top: 30px;
  padding: 30px 0;
  border-top: 1px solid #eae6e0;

  h3 {
    margin-bottom: 20px;
    font-weight: normal;
    line-height: 1.4;
  }
  h4 {
    font-weight: normal;
  }
`;

// Based on the button here: https://plumvillage.app/
const Donate = styled.div`
  a {
    display: inline-block;
    color: #000;
    border-radius: 3px;
    background-color: #f7cc4c;
    padding: 7px 0.8rem;
    font-size: 1rem;
    font-family: pelago, sans-serif;
    font-weight: normal;
    border-bottom: 0;
    text-decoration: none;
  }
`;

const AppStoreLinks = styled.div`
  margin: 0 -5px;

  a {
    display: inline-block;
    margin: 5px;

    img {
      width: auto;
      max-height: 54px;
    }
  }
`;

interface Props {
  hideSearch?: boolean;
  hideFooter?: boolean;
  wide?: boolean;
  children: React.ReactNode;
}

const MIN_SEARCH_LENGTH = 3;
const MAX_SEARCH_RESULTS = 50;

function DefaultLayout({ children, hideSearch, hideFooter, wide }: Props) {
  const [user, setUser] = useState<User | null>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isSigninWithEmailLink, setIsSigninWithEmailLink] =
    useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<Item>>([]);
  const globalSearchIndex = useContext(GlobalSearchContext);
  const content = useContext(ContentContext);
  const t = useTranslations();
  const isResultsCountAboveMax = searchResults.length > MAX_SEARCH_RESULTS;

  const debouncedSearchForTerm = useMemo(
    () =>
      debounce((inputText) => {
        if (
          inputText &&
          inputText.length >= MIN_SEARCH_LENGTH &&
          globalSearchIndex &&
          content
        ) {
          const { docsById, foldersById } = content;
          const results = searchForTerm(
            inputText,
            globalSearchIndex,
            docsById,
            foldersById,
            'en',
            false,
          );

          setSearchResults(results);
        } else {
          setSearchResults([]);
        }
      }, 600),
    [globalSearchIndex, content],
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      debouncedSearchForTerm(event.target.value);
    },
    [debouncedSearchForTerm],
  );

  const handleCloseResults = useCallback(() => {
    setSearchTerm('');
  }, []);

  useEffect(() => {
    if (window) {
      const isSignIn = isSignInWithEmailLinkFirebase(
        getAuth(),
        window.location.href,
      );
      setIsSigninWithEmailLink(isSignIn);
    }
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      setUser(authUser);
    });

    return unsubscribe;
  }, []);

  return (
    <Wrapper $wide={wide || false}>
      {isSigninWithEmailLink && (
        <div className="warning">
          <h3>{t('signIn.error.failed')}</h3>
          <p>
            <RichText>
              {(tags) => t.rich('signIn.error.useSameDeviceSuggestion', tags)}
            </RichText>
          </p>
        </div>
      )}

      {!isSigninWithEmailLink && (
        <>
          <main>{children}</main>

          {!hideSearch && (
            <SearchArea>
              <input
                type="search"
                placeholder={t('search.inputPlaceholder')}
                value={searchTerm}
                onChange={handleSearch}
              />
              {searchTerm && searchTerm.length >= MIN_SEARCH_LENGTH && (
                <>
                  <p>
                    {isResultsCountAboveMax
                      ? t('search.foundCountMax', {
                          count: searchResults.length,
                          max: MAX_SEARCH_RESULTS,
                        })
                      : t('search.foundCount', {
                          count: searchResults.length,
                        })}
                    <CloseButton onClick={handleCloseResults}>
                      {t('common.closeResultsButton')}
                    </CloseButton>
                  </p>
                  <Listing>
                    {(isResultsCountAboveMax
                      ? searchResults.slice(0, MAX_SEARCH_RESULTS)
                      : searchResults
                    ).map((resultItem) => (
                      <ItemInList
                        key={resultItem.id}
                        item={resultItem}
                        showBreadcrumbs
                      />
                    ))}
                  </Listing>
                  {searchResults.length > 5 && (
                    <p>
                      <CloseButton onClick={handleCloseResults}>
                        {t('common.closeResultsButton')}
                      </CloseButton>
                    </p>
                  )}
                </>
              )}
              {searchTerm && searchTerm.length < MIN_SEARCH_LENGTH && (
                <p>{t('search.tooFewCharactersMessage')}</p>
              )}
            </SearchArea>
          )}

          {!hideFooter && (
            <>
              <Footer>
                <h3>
                  <RichText>
                    {(tags) =>
                      t.rich('footer.mobileAppPromotion', {
                        ...tags,
                        anchor: (chunks) => (
                          <a
                            href={'https://plumvillage.app/'}
                            target={'_blank'}
                            rel="noopener"
                          >
                            {chunks}
                          </a>
                        ),
                      })
                    }
                  </RichText>
                </h3>
                <AppStoreLinks>
                  <a
                    href="https://apps.apple.com/us/app/plum-village-zen-meditation/id1273719339"
                    title={t('footer.appStoreLink')}
                  >
                    <img
                      src={appStoreBadge.src}
                      alt={t('footer.appStoreLink')}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=org.plumvillageapp&utm_source=web.plumvillage.app&utm_campaign=play-store-badge&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    title={t('footer.googlePlayLink')}
                  >
                    <img
                      src={playStoreBadge.src}
                      alt={t('footer.googlePlayLink')}
                    />
                  </a>
                </AppStoreLinks>
                <h3>{t('footer.description')}</h3>
                <Donate>
                  <a
                    href={'https://plumvillage.app/donations/'}
                    target={'_blank'}
                    rel="noopener"
                  >
                    {t('footer.donate')}
                  </a>
                </Donate>
              </Footer>
              {user && (
                <p>
                  <small>
                    <RichText>
                      {(tags) =>
                        t.rich('common.loginInfo', {
                          ...tags,
                          user: user.email || '',
                        })
                      }
                    </RichText>{' '}
                    <StyledLink href="/logout">{t('common.logout')}</StyledLink>
                  </small>
                </p>
              )}
            </>
          )}
        </>
      )}
    </Wrapper>
  );
}

export default DefaultLayout;
