import React from 'react';
import { User } from 'firebase/auth';
import { Content } from './types';

export type CurrentUserContextType = {
  user: User | null;
  authLoaded: boolean;
};

export const CurrentUserContext = React.createContext<CurrentUserContextType>({
  user: null,
  authLoaded: false,
});

export const ContentContext = React.createContext<Content | null>(null);
