import React from 'react';
import elasticlunr from 'elasticlunr';
import { Item, Folder } from './types';

export const createEmptyIndex = (): elasticlunr.Index<Item> => {
  const elasticlunrIndex = elasticlunr<Item>();
  elasticlunrIndex.setRef('id');
  elasticlunrIndex.addField('title');
  elasticlunrIndex.addField('content');
  elasticlunrIndex.addField('author');
  elasticlunrIndex.addField('tags');
  // Return empty index. Indexing will be done after first render
  return elasticlunrIndex;
};

export const GlobalSearchContext =
  React.createContext<elasticlunr.Index<Item> | null>(createEmptyIndex());

const INDEXING_BATCH_SIZE = 20;
export const indexWithoutBlocking = (
  items: Item[],
  searchIndex: elasticlunr.Index<Item>,
  status: { indexing: boolean },
  completionCallback: () => void,
): void => {
  if (!status.indexing) {
    // Stop indexing if the indexing flag was set to false
    return;
  }

  if (items.length > INDEXING_BATCH_SIZE) {
    // Still many items to index, so index a batch now and do the rest later
    const itemsToIndexNow = items.slice(0, INDEXING_BATCH_SIZE);
    const itemsToIndexLater = items.slice(INDEXING_BATCH_SIZE);
    itemsToIndexNow.forEach((item) => {
      searchIndex.addDoc(item);
    });
    // Wait a few milliseconds to allow for any user interaction
    setTimeout(() => {
      indexWithoutBlocking(
        itemsToIndexLater,
        searchIndex,
        status,
        completionCallback,
      );
    }, 10);
  } else {
    // Only a few items left to index, so index them all now
    items.forEach((item) => {
      searchIndex.addDoc(item);
    });
    // Indexing finished, call the completion callback:
    completionCallback();
  }
};

export const searchForTerm = (
  text: string,
  searchIndex: elasticlunr.Index<Item>,
  docsMap: { [key: string]: Item },
  foldersMap: { [key: string]: Folder },
  primaryLanguage: string,
  hideOtherLanguages: boolean,
): Array<Item> => {
  if (text) {
    const result = searchIndex.search(text, {
      fields: {
        title: {
          boost: 3,
        },
        content: {
          boost: 2,
        },
        author: {
          boost: 1,
        },
        tags: {
          boost: 1,
        },
      },
      expand: true,
    });
    const items = result.map((item) => docsMap[item.ref]).filter(Boolean);

    // Exclude doc if not contained in a folder
    let filteredItems = items.filter((doc) => doc.folder);

    if (hideOtherLanguages) {
      // Only show items in user's primary language
      filteredItems = filteredItems.filter((doc) => {
        const folder = foldersMap[doc.folder];
        const folderLang = (folder && folder.lang) || 'en';
        return folderLang === primaryLanguage;
      });
    }

    return filteredItems;
  }
  return Object.values(docsMap);
};
