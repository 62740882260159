import { Timestamp } from 'firebase/firestore';
import { Folder } from './types';
import { FolderBreadcrumb } from './uiTypes';

// Original: https://stackoverflow.com/a/62750096/174979
// Added TS to above to create this fn.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = (fn: (...args: any[]) => any, delay = 1000) => {
  let timerId: ReturnType<typeof setTimeout>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

export const getYouTubeId = (sourceUrl: string) => {
  let match = sourceUrl.match(/v=([^&]+)/);
  if (!match) {
    match = sourceUrl.match(/youtu\.be\/([^?]+)/);
  }
  if (!match) {
    match = sourceUrl.match(/shorts\/([^?]+)/);
  }
  return match && match[1];
};

export const getLanguageFromBreadcrumbs = (breadcrumbs: FolderBreadcrumb[]) => {
  if (breadcrumbs.length > 1) {
    const name = breadcrumbs[0].name.toLowerCase();

    if (name.includes('français') || name.includes('francais')) {
      return 'fr';
    } else if (name.includes('español')) {
      return 'es';
    } else if (name.includes('việt')) {
      return 'vi';
    } else if (name.includes('italiano')) {
      return 'it';
    } else if (name.includes('deutsch')) {
      return 'de';
    } else if (name.includes('português')) {
      return 'pt';
    } else if (name.includes('thai') || name.includes('ไทย')) {
      return 'th';
    }
  }
  return 'en';
};

export function getAnyParentFolders(
  folders: Array<Folder>,
  folder: Folder,
): Array<Folder> {
  if (folder && folder.parent) {
    const parent = folders.find((f) => folder.parent === f.id);
    if (parent) {
      return [...getAnyParentFolders(folders, parent), parent];
    }
  }
  return [];
}

export const getFolderBreadcrumbs = (
  folders: Folder[],
  folderId: string | null | undefined,
): FolderBreadcrumb[] => {
  const folderBreadcrumbs = [];
  if (folderId) {
    const folder = folders.find((f) => f.id === folderId);
    if (folder) {
      const folderChain = [...getAnyParentFolders(folders, folder), folder];
      folderBreadcrumbs.push(
        ...folderChain.map((f) => ({
          path: `/${f.tab}/${f.slug}`,
          name: f?.title || '',
        })),
      );
    }
  }
  return folderBreadcrumbs;
};

export function getDurationMinutes(duration: number) {
  return duration
    ? `${Math.floor(duration / 60)}:${(duration % 60).toString().padStart(2, '0')}`
    : null;
}

/**
 * Return a copy of a firebase Timestamp as a plain JS object
 * Needed because only plain objects can be passed to Client Components from Server Components
 */
export function parseTimestamp(timestamp: Timestamp | undefined) {
  if (!timestamp) {
    return null;
  }
  return JSON.parse(JSON.stringify(timestamp));
}
